<template>
  <div class="font">
    <ayl-berad-nav :nav="nav" />
    <div class="content-box mt20px">
      <div class="content-main">
        <div style="margin-bottom: 20px">
          <el-date-picker
            v-model="pickerDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="onPickerDate"
            value-format="timestamp"
          ></el-date-picker>
          <el-button
            style="margin-left: 10px;"
            class="btn-success-hw"
            type="primary"
            @click="searchData"
          >查询</el-button>
        </div>
        <el-table :data="tableData" fit style="width: 100%">
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="sexDes" label="性别"></el-table-column>
          <el-table-column prop="personTypeDes" label="人员类型"></el-table-column>
          <el-table-column prop="companyName" label="所属企业"></el-table-column>
          <el-table-column prop="bidName" label="所属标段"></el-table-column>
          <!-- <el-table-column prop="address" label="排班网格"></el-table-column> -->
          <el-table-column prop="checkObject" label="检测项"></el-table-column>
          <el-table-column prop="checkValue" label="检测值"></el-table-column>
          <el-table-column prop="reportTime" label="检测时间"></el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalNum"
            :page-sizes="[10, 20, 30, 50]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    // eslint-disable-next-line no-unused-vars
    const vm = this;
    return {
      nav: [
        {
          name: "人员健康明细"
        }
      ],
      personId: this.$route.query.id,
      tableData: [],
      pickerDate: [],
      //   入参
      totalNum: 0,
      pageSize: 10,
      pageIndex: 1,
      endTime: null,
      startTime: null,

      pickerOptions: {
        shortcuts: [
          {
            text: "当天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },

  methods: {
    // 选择日期
    onPickerDate(val) {
      this.startTime = val ? val[0] : null;
      this.endTime = val ? val[1] : null;
      this.getData();
    },
    // 查询
    searchData() {
      this.getData();
    },

    // 页数/条数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      (this.pageIndex = val), this.getData();
    },

    // 获取列表数据
    async getData() {
      let params = {
        // personId: "1589941422634rMgolM",
        personId: this.personId,
        startTime: this.startTime,
        endTime: this.endTime
      };
      await this.$api_hw
        .operationSafetyManagement_getHealthDetailList(params)
        .then(res => {
          this.tableData = res.data;
          this.totalNum = res.totalNum;
          this.tableData.splice(this.pageIndex * this.pageSize);
          if (this.pageIndex > 1) {
            this.tableData.splice(0, (this.pageIndex - 1) * this.pageSize);
          }
        });
    }
  },
  async activated() {
    await this.getData();
  }
};
</script>
<style lang='sass' scoped>

.font /deep/ .el-table thead
    color: rgb(38, 38, 38);
    font-weight: 600;
    font-size: 14px;
.page
    display: flex
    margin-top: 20px
    justify-content: flex-end
</style>